<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <DistibutorMenu></DistibutorMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row wrap class="rowBg" v-if="userDetails.isActive">
          <v-col cols="5" md="2" class="ml-5">
            <router-link
              :to="{
                name: 'pharmaDistributorAllProducts',
              }"
              style="text-decoration:none"
            >
              <v-layout
                class="orderStatBox"
                justify-center
                align-center
                style="background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);color:#ffffff; "
              >
                <v-icon color="#ffffff">mdi-bag-checked</v-icon>
              </v-layout>
              <p class="orderStatText ml-5">Add Products</p>
            </router-link>
          </v-col>

          <v-col cols="5" md="2" class="ml-5">
            <router-link
              :to="{
                path: '/edit/distributor/' + this.distributorId,
              }"
              style="text-decoration:none"
            >
              <v-layout
                class="orderStatBox"
                justify-center
                align-center
                style="background: linear-gradient(192.88deg, #111212 43.53%, #DED84C 90.69%);color:#ffffff; "
              >
                <v-icon color="#ffffff">mdi-store-outline</v-icon>
              </v-layout>
              <p class="orderStatText ml-5">My Store</p>
            </router-link>
          </v-col>

          <v-col cols="5" md="2" class="ml-5">
            <v-dialog v-model="dialogDiscount" persistent max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  v-bind="attrs"
                  v-on="on"
                  class="orderStatBox"
                  justify-center
                  align-center
                  style="background: linear-gradient(192.88deg, #111212 43.53%, #618BBD 90.69%);color:#ffffff; "
                >
                  <v-icon color="#ffffff">mdi-alert-octagon-outline</v-icon>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="#000000" @click="dialogDiscount = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-layout wrap>
                    <v-col cols="5" offset="1">
                      <v-layout
                        wrap
                        style="background-color:#000000; border-radius:5px; color:#ffffff;height:150px;"
                      >
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12" align="center" justify="12">
                              My Discounts
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" align="center" justify="12">
                              <span class="text-h3">23</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-layout>
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                        small
                        color="#72bd61"
                        label="Enable Discount/Disable Discount"
                      ></v-switch>
                    </v-col>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
            <p class="orderStatText ml-5">My Discount</p>
          </v-col>

          <v-col cols="5" md="2" class="ml-5">
            <v-dialog v-model="dialogPromoAlert" persistent max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  v-bind="attrs"
                  v-on="on"
                  class="orderStatBox"
                  justify-center
                  align-center
                  style="background: linear-gradient(192.88deg, #111212 43.53%, #B55620 90.69%);color:#ffffff; "
                >
                  <v-icon color="#ffffff">mdi-basket-fill</v-icon>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="#000000" @click="dialogPromoAlert = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-layout wrap>
                    <v-col cols="12" align="center" justify="center">
                      <v-img
                        alt="System Logo"
                        class="shrink"
                        contain
                        :src="'img/promoImg.png'"
                        transition="scale-transition"
                        width="150"
                      />
                    </v-col>
                  </v-layout>
                  <v-layout wrap>
                    <v-col cols="12" align="center" justify="center">
                      <h5 style="color:#000000;">Send Promo Message</h5>
                    </v-col>
                  </v-layout>

                  <v-layout wrap>
                    <v-col cols="12" align="center" justify="center">
                      <v-textarea outlined label="Type Promo Message">
                      </v-textarea>
                    </v-col>
                  </v-layout>

                  <v-layout wrap>
                    <v-col cols="12" align="center" justify="center">
                      <p>
                        <v-select
                          v-model="promoData.category"
                          :items="categories"
                          item-text="name"
                          item-value="productCategoryId"
                          label="Select Category"
                          outlined
                          dense
                        ></v-select>
                      </p>
                      <p>
                        <v-select
                          v-model="promoData.region"
                          :items="productRegion"
                          item-text="name"
                          item-value="regionId"
                          label="Select Region"
                          multiple
                          outlined
                          dense
                        ></v-select>
                      </p>
                    </v-col>
                  </v-layout>

                  <v-layout wrap>
                    <v-col cols="12" align="center" justify="center">
                      <v-btn class="buttonStyle">
                        Send
                      </v-btn>
                    </v-col>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
            <p class="orderStatText ml-5">Promo Alert</p>
          </v-col>
        </v-row>

        <v-row wrap class="mt-5" v-if="userDetails.isActive">
          <v-col cols="12" class="rowBg">
            <v-row wrap>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="allProducts"
                  item-key="name"
                  class="elevation-1"
                >
                  <template
                    v-slot:item.image="{ item }"
                    style="width:100px !important;"
                  >
                    <td>
                      <p>
                        <v-img
                          class="ma-2"
                          width="70"
                          height="70"
                          :src="item.productImagePath"
                          cover
                        />
                      </p>
                    </td>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <v-layout wrap v-if="item.isActive == false">
                      <span style="color:#72bd61">{{ item.isActive }}</span>
                    </v-layout>
                    <v-layout v-if="item.isActive !== true">
                      <span style="color:#BD2121">{{ item.isActive }}</span>
                    </v-layout>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                  <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Distributor</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
              </v-layout>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DistibutorMenu from "../others/DistributorMenu.vue";

export default {
  name: "DistributorProducts",

  components: {
    DistibutorMenu,
  },

  created() {
    this.distributorId = localStorage.getItem("distributorId");
    this.userId = localStorage.getItem("userId");
    this.getUserDetails();
    this.getRegions();
    this.getProductCat();
    this.getUserProducts();
  },

  computed: {
    headers() {
      return [
        {
          text: "Image",
          value: "image",
        },
        { text: "Name", value: "name" },
        { text: "Status", value: "isActive" },
        { text: "Price ($)", value: "price" },
        { text: "Quantity", value: "quantity" },
      ];
    },
  },

  data: () => ({
    distributorId: "",
    dialogDiscount: false,
    dialogPromoAlert: false,
    categories: [],
    productRegion: [],
    allProducts: [],
    promoData: {
      region: [],
    },
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/#/overview/distributor_dashboard/en/home",
      },
      {
        text: "Products",
        disabled: true,
        href: "/#/en/distributor/0/products",
      },
    ],
    userDetails:{
      isActive:true,
    },
  }),
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getRegions() {
      this.$http
        .get(`${process.env.VUE_APP_URL}regions`)
        .then((response) => {
          this.productRegion = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getUserProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product/user/` + this.userId)
        .then((response) => {
          if (response.data.success == true) {
            this.allProducts = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding: 30px !important;
  width: 120px;
  height: 80px;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: #000000;
  font-size: 14px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
